
import { Component, Inject, Watch } from 'vue-property-decorator'
import { MetaInfo } from 'vue-meta'
import { VueConstructor } from 'vue'

import { AsyncDataContext } from '@/shared'
import { AmplitudeType, AppAmplitudeEvent, IAmplitude } from '@/shared/services/amplitude'
import { logger } from '@/core'

import { WishlistMixin } from '@/modules/favourites/shared/mixins/WishlistMixin.vue'
import { EventDesktopView, EventMobileView } from '@/modules/events/views/Event/versions'

import { EventDetails } from '../../components/molecules/EventDetails'
import { EventsRepository } from '../../repositories'
import { Event, EventModel } from '../../models'
import { EventsRepositoryType, IEventsRepository } from '../../contracts'

const marked = require('marked')

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<EventPage>({
  name: 'EventPage',
  components: { EventDetails },
  async asyncData (context: AsyncDataContext): Promise<any> {
    const eventsRepository = context.app.$container.get<EventsRepository>(EventsRepositoryType)
    const eventData = await eventsRepository.fetchEvent(context.route.params.slug)
    return {
      eventData
    }
  },
  metaInfo (): MetaInfo {
    return {
      title: this.eventData?.title ?? '---',
      meta: [
        {
          name: 'og:title',
          content: this.eventData?.title ?? '---'
        },
        {
          name: 'og:image',
          content: this.eventData?.images[0].file ?? undefined
        },
        {
          vmid: 'description',
          name: 'og:description',
          content: this.eventData?.metaDescription ?? '---'
        },
        {
          vmid: 'desc',
          name: 'description',
          content: this.eventData?.metaDescription ?? '---'
        },
        {
          vmid: 'keywords',
          name: 'keywords',
          content: this.eventData?.keywords ?? ''
        }
      ]
    }
  },
  mounted (): void {
    if (typeof window !== 'undefined') {
      this.amplitude.emit(
        AppAmplitudeEvent.ON_EVENT_PAGE,
        { isPromoted: this.model?.get('isPromoted'), category: this.model?.category().key }
      )
    }

    this.boot()
  }
})
export class EventPage extends WishlistMixin {
  @Inject({ from: 'isMobile', default: false })
  public isMobile!: () => boolean

  public eventsRepository =
    this.$container.get<IEventsRepository>(EventsRepositoryType)

  protected readonly amplitude =
    this.$container.get<IAmplitude>(AmplitudeType)

  protected eventData!: Event
  public model: EventModel | null = null

  public get component (): VueConstructor {
    if (this.isMobile()) {
      return EventMobileView
    }

    return EventDesktopView
  }

  public get slug (): string | undefined {
    return this.$route.params.slug
  }

  protected boot (): void {
    this.model = EventModel.hydrate<EventModel>(this.eventData)
  }

  @Watch('slug')
  protected async onSlugUpdate (value: string): Promise<void> {
    try {
      this.model = await this.eventsRepository.fetchEvent(value)
    } catch (e) {
      logger().log(`[Event Page]: Error while loading event by slug ${(e as Error).message}`, 'log')
    }
  }
}

export default EventPage
