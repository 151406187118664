
import { Component, Prop, Vue } from 'vue-property-decorator'

import { RouteName } from '@/modules/events/config'
import { AmplitudeType, AppAmplitudeEvent, IAmplitude } from '@/shared/services/amplitude'
import { capitalizeFirstLetter } from '@/shared'

import { EventModel } from '../../../models'

import { EventDetail } from '../EventDetail'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component({ name: 'EventDetails', components: { EventDetail } })
export class EventDetails extends Vue {
  @Prop({ type: Object, required: true })
  protected readonly event!: EventModel

  protected readonly amplitude =
    this.$container.get<IAmplitude>(AmplitudeType)

  public get categories (): string {
    if (this.event.get('categories').length === 0) {
      return '---'
    }

    return this.event.get('categories').map((category) => category.label).join((', '))
  }

  public async showOnMap (): Promise<void> {
    this.amplitude.emit(AppAmplitudeEvent.ON_EVENT_ON_MAP)
    window.open(this.event.get('googleMapsLink'), '_blank')
  }

  public readonly capitalize = capitalizeFirstLetter
}

export default EventDetails
