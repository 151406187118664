
import { Component, Prop, Ref } from 'vue-property-decorator'

const marked = require('marked')

import { WishlistMixin } from '@/modules/favourites/shared/mixins'
import { AmplitudeType, AppAmplitudeEvent, IAmplitude } from '@/shared/services/amplitude'

import { EventDetails } from '../../../components/molecules/EventDetails'
import { EventModel } from '../../../models'
import { carouselConfig } from '../Mobile.config'

/**
 * @author Javlon Khalimjonov <khalimjanov2000@gmail.com>
 */
@Component<EventMobileView>({
  name: 'EventMobileView',
  components: { EventDetails },
  mounted (): void {
    this.buyTicketBtnObserverFn.observe(this.eventTop)
  }
})
export class EventMobileView extends WishlistMixin {
  @Prop({ type: Object, required: true })
  public event!: EventModel

  @Ref('buyTicketBtnRef')
  public buyTicketBtn!: HTMLDivElement

  @Ref('eventTopRef')
  public eventTop!: HTMLDivElement

  protected readonly amplitude =
    this.$container.get<IAmplitude>(AmplitudeType)

  public readonly config = carouselConfig

  protected buyTicketBtnObserverFn =
    new IntersectionObserver(this.buyTicketBtnObserverFnCb, { threshold: 1 })

  protected buyTicketBtnObserverFnCb (entries: any, observer: any): void {
    entries.forEach((entry: any) => {
      if (entry.isIntersecting && typeof this.buyTicketBtn !== 'undefined') {
        this.buyTicketBtn.style.transform = 'translateY(0%)'
      } else {
        this.buyTicketBtn.style.transform = 'translateY(100%)'
      }
    })
  }

  /**
   * Determined the parsed variant of the event's marked description.
   */
  public get description (): string {
    return marked.parse(this.event?.get('description') ?? '')
  }

  public get hasTicketUrl (): boolean {
    return typeof this.event !== 'undefined' && this.event.get('ticketUrl') !== null
  }

  public get isFavourite (): boolean {
    if (!this.wishlist) {
      return false
    }

    return this.wishlist.items.some(item => item.id === this.event?.get('id'))
  }

  public toggleFavourite (): void {
    if (this.isFavourite) {
      this.remove(this.event!.get('id'))
    } else {
      this.amplitude.emit(AppAmplitudeEvent.ON_SAVE_EVENT, {
        id: this.event!.get('id'),
      })
      this.add({
        id: this.event!.get('id'),
        validUntil: this.event!.get('startDate'),
        item: this.event!.toObject()
      })
    }
  }

  public goBack (): void {
    if (!window.history.length) {
      this.$router.push({ path: '/' })
    } else {
      this.$router.go(-1)
    }
  }

  public goToTicketUrl (): void {
    if (this.event && this.event?.get('ticketUrl')) {
      this.amplitude.emit(AppAmplitudeEvent.ON_BUY_TICKET)
      if (this.event.get('ticketUrl')?.startsWith('http') || this.event.get('ticketUrl')
        ?.startsWith('https')) {
        window.open(this.event.get('ticketUrl') as string, '_blank')
      } else {
        window.open(`https://${this.event.get('ticketUrl')}`, '_blank')
      }
    }
  }
}

export default EventMobileView
