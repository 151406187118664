
import { Component, Prop } from 'vue-property-decorator'

import { WishlistMixin } from '@/modules/favourites/shared/mixins/WishlistMixin.vue'
import { AmplitudeType, AppAmplitudeEvent, IAmplitude } from '@/shared/services/amplitude'

import { EventDetails } from '../../../components/molecules/EventDetails'
import { RouteName } from '../../../config'
import { EventModel } from '../../../models'

const marked = require('marked')

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<EventDesktopView>({
  name: 'EventDesktopView',
  components: { EventDetails }
})
export class EventDesktopView extends WishlistMixin {
  @Prop({ type: Object, required: true })
  public event!: EventModel

  protected readonly amplitude =
    this.$container.get<IAmplitude>(AmplitudeType)

  public get description (): string {
    return marked.parse(this.event?.get('description') ?? '')
  }

  public get hasTicketUrl (): boolean {
    return typeof this.event !== 'undefined' && this.event.get('ticketUrl') !== null
  }

  public get isFavourite (): boolean {
    if (!this.wishlist) {
      return false
    }

    return this.wishlist.items.some(item => item.id === this.event?.get('id'))
  }

  public toggleFavourite (): void {
    if (this.isFavourite) {
      this.remove(this.event!.get('id'))
    } else {
      this.amplitude.emit(AppAmplitudeEvent.ON_SAVE_EVENT, {
        id: this.event!.get('id'),
      })
      this.add({
        id: this.event!.get('id'),
        validUntil: this.event!.get('startDate'),
        item: this.event!.toObject()
      })
    }
  }

  public goBack (): void {
    this.$router.back()
  }

  public goToTicketUrl (): void {
    if (this.event && this.event?.get('ticketUrl')) {
      this.amplitude.emit(AppAmplitudeEvent.ON_BUY_TICKET)
      if (this.event.get('ticketUrl')?.startsWith('http') || this.event.get('ticketUrl')
        ?.startsWith('https')) {
        window.open(this.event.get('ticketUrl') as string, '_blank')
      } else {
        window.open(`https://${this.event.get('ticketUrl')}`, '_blank')
      }
    }
  }

  public showInMap (): void {
    this.$router.push({ name: `events.${RouteName.Map}` })
  }
}

export default EventDesktopView
